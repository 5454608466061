// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  common: {
    default_ending_words: `The element is ending!`,
    never_remind_me: `Never remind me`,
  },
  nav: {
    home: `Home`,
    docs: `Docs`,
    login_now: `Login Now`, 
    hi: `Hi`,
    docs_description: `Need help? \n Please check how to use Learmonade.`,
    documentation: `How to use?`,
    dictation: `Dictation`,
    favorites: `Favorites`,
  },
  header: {
    need_help: `Need Help?`,
    logout: `Logout`,
    unlimited: `unlimited`,
    upgrade_plan: `Upgrade Plan`,
    expiration_date: `Expiration Date`,
    your_credits: `Your Credits:`,
    light_mode: `Light Mode`,
    dark_mode: `Dark Mode`,
    logout_error: `Unable to logout!`,
  },
  error: {
    email_already_exist: `A user with that e-mail already exists.`,
    email_or_pwd_invalid: `Email or password invalid.`,
    error_401: `Invalid status, please reload page or login again!`,
    error_429: `Too many requests, please try again later!`,
    error_something_wrong: `Something went wrong, please try again!`,
  },
  home: {
    page_title: `The starting point for your language learning`,
    title: `Start your \n dictation training with`,
    description: `The starting point for your language learning. Materials customization helps you learn new language faster and better.`,
    start_free: `Start for Free`,
    help_title: `What Learmonade \n helps you?`,
    help_subtitle_1: `Resource-rich`,
    help_description_1: `Various dictation material update regularly based on Youtube API.`,
    help_subtitle_2: `Interesting`,
    help_description_2: `Students are able to have dictation material with video mode.`,
    help_subtitle_3: `Auto-checking`,
    help_description_3: `Easy to review key sentences with learning report and auto-checking feature.`,
    tutorial_subtitle: `TUTORIAL DOCS`,
    tutorial_title: `Easy to learn!`,
    tutorial_description: `Explore the tutorial docs to figure how to use Learmonade!`,
    tutorial_btn: `Let's explore`,  
    report_subtitle: `Professional Kit`,  
    report_title: `Report Review`, 
    theme_mode_subtitle: `Easy switch between styles.`, 
    theme_mode_title: `Dark mode`, 
    theme_mode_description: `A dark theme that feels easier on the eyes.`, 
    materials_subtitle: `clean & clear`, 
    materials_title: `Powered by Youtube, up-to-date materials`, 
    bottom_title: `Get started with \n Learmonade today`, 
    start_now: `Start Now`, 
  },
  login: {
    welcome: `Hi, Welcome back`,
    page_title: `Login`, 
    title: `Sign in to Learmonade`,
    new_user: `New user?`, 
    create_account: `Create an account`, 
    email: `Email address`, 
    password: `Password`, 
    forget_password: `Forgot password?`, 
    login: `Login`, 
    or: `OR`, 
    google_login: `Continue with Google`, 
    require_email: `Email is required`,
    require_valid_email: `Email must be a valid email address`,
    require_password: `Password is required`,
  },
  register: {
    welcome: `Learn English more effectively with Learmonade`,
    title: `Get started for your learning.`,
    page_title: `Register`,
    already_has_account: `Already have an account?`,
    sign_in: `Sign in`,
    first_name: `First name`,
    last_name: `Last name`,
    email_address: `Email address`,
    password: `Password`,
    password_confirm: `Password confirm`,
    create_account: `Create account`,
    agreement: `By signing up, I agree to`,
    service_terms: `Terms of Service`,
    privacy_policy: `Privacy Policy`,
    and: `and`,
    require_first_name: `First name required`,
    require_last_name: `Last name required`,
    require_email: `Email is required`,
    require_valid_email: `Email must be a valid email address`,
    require_password: `Password is required`,
    require_password_confirm: `Passwords must match`,
  },
  verify_code: {
    page_title: `Verify Code`,
    title: `Please check your email!`,
    sending: `Sending email...`,
    description_1: `We've sent an email to {{email}}`,
    description_2: `Please check and complete your account verification.`,
    cannot_receive: `Don’t receive email? Check your`,
    spam_email: `spam email`,
    and: `and`,
    resend: `Resend`,
    return: `Return to sign in`,
    errors: `Please add your '?email=xxx@xx' as the suffix to the browser url and refresh the page.`,
    success: `Email sent successfully!`
  },
  verify_user: {
    page_title: `Verify User`,
    success_title: `Congratulation!`,
    success_description: `Your account has been registered successfully! Please login to Learmonade and explore your learning.`,
    verifying: `Verifying...`,
    error_title: `Sorry, something wrong, please try again!`,
    error_description: `We couldn’t verify your account temporarily. Please reload the page or login your account to resend a new verification email.`,
    login: `Go to Login`,
  },
  new_password: {
    page_title: `New Password`,
    title: `Confirm successfully!`,
    description: `Please enter your new password in below box.`,
    password: `Password`,
    confirm_password: `Confirm New Password`,
    update_password: `Update Password`,
    return_login: `Return to sign in`,
    require_eight_password_chars: `Password must be at least 8 characters`,
    require_password: `Password is required`,
    require_password_confirm: `Confirm password is required`,
    require_password_match: `Passwords must match`,
    toast_change_success: `Change password success!`,
  },
  reset_password: {
    page_title: `Reset Password`,
    title: `Forgot your password?`,
    description: `Please enter the email address associated with your account and We will email you a link to reset your password.`,
    email_address: `Email address`,
    send_request: `Send Request`,
    return_sign_in: `Return to sign in`,
    require_email: `Email is required`,
    require_valid_email: `Email must be a valid email address`,
  },
  success_request: {
    page_title: `Success Request`,
    title: `Request sent successfully!`,
    description_1: `We've sent an email to {{email}}`,
    description_2: `Please check the confirm link in your inboxes to reset your password.`,
    do_not_receive: `Don’t receive a email?`,
    resend_email: `Resend email`,
    return_sign_in: `Return to sign in`,
  },
  pricing: {
    page_title: `Pricing`,
    title_1: `Flexible plans for your`,
    title_2: `learning period and needs`,
    description: `Choose your plan and make language learning magic`,
    popular: `POPULAR`,
    basic: `basic`,
    starter: `starter`,
    premium: `premium`,
    one_month_plan: `1 Month Plan`,
    three_months_plan: `3 Months Plan (saving $2)`,
    six_months_plan: `6 Months Plan (saving $5)`,
    unlimited_credits: `Unlimited element credits`,
    latest_materials: `Up-to-date learning materials`,
    auto_checking: `Auto dictation checking`,
    dictation_report: `Dictation report`,
    one_month_money_saving: `Money saving`,
    three_months_money_saving: `Money saving (up to $2)`,
    six_months_money_saving: `Money saving (up to $5)`,
    one_month_fixed_price: `Future features with fixed price`,
    three_months_fixed_price: `Future features with fixed price (3-Mon)`,
    six_months_fixed_price: `Future features with fixed price (6-Mon)`,
    choose_basic: `choose basic`,
    choose_starter: `choose starter`,
    choose_premium: `choose premium`,
  },
  faqs: {
    page_title: `Faqs`,
    title: `Frequently asked questions`,
    question_1: `How to contact Learmonade for further support?`,
    answer_1: `Contact us with Email Address:`,
    question_2: `Any guide for Learmonade?`,
    answer_2_please: `Please`,
    answer_2_click: `click`,
    answer_2_read_details: `to read details.`,
  },
  docs: {
    page_title_prefix: `Tutorial:`,
    title: `How to use Learmonade?`,
    nav_title: `Learmonade Tutorial`,
    nav_home: `Home`,
    nav_docs: `Docs`,
    steps_title: `How to use Learmonade?`,
    steps_caption: `Start learning language with Learmonade.`,
    steps_description: `In this guidebook, we'll introduce a basic learning flow with Learmonade and keyboard shortcuts for dictation learning. It's a quick start for beginners. Let's get started!`,
    step_1_title: `1. Basic Learning Flow for Beginners`,
    step_1_description: `In this video, we will introduce a basic learning flow for Learmonade. This learning method will help beginners quickly improve their typing skills while practising English with the help of Learmonade.`,
    step_2_title: `2. Bluetooth Device Settings`,
    step_2_description_1: `If using bluetooth headphones for learning, please make sure you have turned on the `,
    step_2_description_2: `Bluetooth Device`,
    step_2_description_3: `in settings.`,
    step_3_title: `3. Keyboard Shortcuts`,
    step_3_description: `Basic keyboard shortcuts for dictation learning.`,
    step_4_title: `4. How to close subtitles from video?`,
    step_4_description: "Step1,turn on `Showing Video Controls` in setting dialogs. Step2,turn off subtitles in youtube setting as showing in picture 2 below. Step3,Reload page.",
    step_5_title: `5. Contact us`,
    step_5_description: `If you have any questions, feel free to contact us with`,
  },
  dictation: {
    first_visit_title: `First Visit Guide`,
    first_visit_description: `A quick guide to start your learning in Learmonade:`,
    first_visit_list_1_1: `3 credits monthly`,
    first_visit_list_1_2: ` for free plan users, each learning element costs one credit and the credits `,
    first_visit_list_1_3: `reset on 1st of each month`,
    first_visit_list_1_4: `. Please check your credits in the top-right corner by clicking your avatar.`,
    first_visit_list_2_1: `Subscribers`,
    first_visit_list_2_2: ` have unlimited credits to access all learning elements.`,
    first_visit_list_3_1: `If using bluetooth headphones for learning, please make sure you have turned on the `,
    first_visit_list_3_2: `Bluetooth Device`,
    first_visit_list_3_3: ` in settings.`,
    first_visit_list_4_1: `Please read the `,
    first_visit_list_4_2: `Docs`,
    first_visit_list_4_3: ` for basic learning flow and more details. It is highly recommended that you read the Docs before learning.`,
    first_visit_list_5: `Keyboard Shortcuts: `,
    
    settings_title: `Dictation Settings`,
    settings_showing_video: `Showing Video`,
    settings_showing_video_controls: `Showing Video Controls`,
    settings_bluetooth_device: `Bluetooth Device`,
    settings_reset_learning: `Reset Learning`,
    settings_reset: `Reset`,

    typing_area_placeholder: `Type your answer`,
    typing_area_loading: `Loading your existing answers...`,

    no_credits_title: `Out of Credits`,
    no_credits_content_1: `You have run out of your monthly credits. Please upgrade your plan to get `,
    no_credits_content_2: `unlimited access`,
    no_credits_later: `Later`,
    no_credits_upgrade_plan: `Upgrade Plan`,

    reset_learning_title: `Reset Learning Records`,
    reset_learning_content: `You have not yet finished the dictation. Do you want to reset your learning records?`,
    reset_learning_no: `No`,
    reset_learning_yes: `Yes`,

    auto_play_confirm_title: `Auto Playing Mode`,
    auto_play_confirm_content: `In auto-playing mode, sentences are automatically played in order until the end of this element. Do you want to turn on Auto Playing Mode?`,
    auto_play_confirm_yes: `Yes`,
    auto_play_confirm_no: `No`,

    complete_learning_title: `Congratulation!`,
    complete_learning_content: "You have finished this dictation element. If you want to learn again, please reset learning in `Settings` dialog.",
    complete_learning_ok: `Okay`,

    typing_mode_click_or_press: `Click or press Tab to activate...`,
    typing_mode_metrics: `Metrics:`,
    typing_mode_speed: `Speed:`,
    typing_mode_accuracy: `Accuracy:`,
    typing_mode_typing_settings: `Typing Settings:`,
    typing_mode_ignore_case: `Ignore Case:`,
    typing_mode_ignore_punctuation: `Ignore Punctuation:`,

    report_mode_title: `Learning Report`,
    report_mode_total_score: `Total Score`,
    report_mode_excellent: `Excellent`,
    report_mode_good: `Good`,
    report_mode_poor: `Poor`,
    report_mode_under_60: `Under 60`,
    report_mode_compare: `Compare:`,
    report_mode_your_answer: `Your Answer:`,
    report_mode_score: `Score:`,
    report_mode_accuracy: `Accuracy:`,

    reading_mode: `Reading Mode`,
    typing_mode: `Typing Mode`,
    dictation_mode: `Dictation Mode`,
    report_mode: `Report Mode`,
    close: `Close`,
  },
  admin: {
    settings_title: `Admin Settings`,
    settings_hidden_channels: `Hidden Inactive Channels:`,
    settings_hidden_elements: `Hidden Inactive Elements:`,
    close: `Close`, 
  },
  page_error: {
    title: `Something went wrong!`,
    description: `There was an error, please try to reload page.`,
    reload: `Reload Page`,
  },
  page_404: {
    title: `Sorry, page not found!`,
    description: `Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling.`,
    go_to_home: `Go to Home`,
  },
  payment_success: {
    page_title: `Payment Success`,
    title: `Your payment transaction is successful`,
    description: `Please click to explore your learning!`,
    go_to_explore: `Go to Explore`,
  },
  payment_canceled: {
    page_title: `Payment Canceled`,
    title: `Your payment transaction is canceled`,
    description: `Please email us if you need help!`,
    go_to_home: `Go to Home`,
  }
};

export default en;
